import React from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import cn from "./utils/cn";
import { Trigger } from "@radix-ui/react-dialog";
const CardPlan = ({ children, planName, className }) => {
  return (
    <div
      className={cn(
        ` px-1.5 py-1 text-primary rounded-full font-bold text-xl capitalize h-fit flex items-center justify-center w-fit border-primary border-4`,
        className
      )}
    >
      <span>{planName}</span>
      {children}
    </div>
  );
};
const CardBadge = ({ children }) => {
  return (
    <div className="flex p-1 text-base px-2  capitalize font-semibold shadow-sm items-center rounded-bl-none justify-center text-white rounded-full bg-primary">
      {children}
    </div>
  );
};
const PricingCard = ({ item, aosAnimation, subTitle, planName, isActive }) => {
  return (
    <div
      {...aosAnimation}
      className={`relative p-3  shadow-lg ${
        isActive ? "border-primary  border-8" : ""
      }   flex-1  bg-slate-50 min-w-80 max-w-96 text-start  flex items-stretch flex-col rounded-3xl  mt-6 sm:mt-0 ${
        item.isMostPop ? "mt-10" : ""
      }`}
    >
      <div className="px-8 pt-5 space-y-4 border-b">
        <div className="flex justify-center items-center">
          <CardPlan planName={planName} className="flex gap-2">
            {subTitle && <CardBadge>{subTitle}</CardBadge>}
          </CardPlan>
        </div>

        <span className="text-3xl font-bold text-primary">{item.name}</span>

        {item.price && (
          <>
            {" "}
            <div className=" text-3xl font-sans    text-center  font-bold">
              ${item.price}/hour
            </div>
          </>
        )}

        <p className="text-primary font-semibold text-center ">{item.desc}</p>
      </div>
      <ul
        className="p-8 space-y-3 overflow-hidden pt-3"
        data-aos-mirror={"true"}
      >
        {item.features.map((featureItem, idx) => (
          <li
            key={idx}
            className="flex  text-gray-700  capitalize items-center gap-2"
            data-aos="fade-left"
            data-aos-mirror={"true"}
            data-aos-delay={(idx + 1) * 150}
            data-aos-offset={-100} // offset (in px) from the original trigger point
          >
            <IoIosCheckmarkCircleOutline />
            {featureItem}
          </li>
        ))}
      </ul>
      <Trigger asChild>
        <button
          className={cn(
            ` mt-auto capitalize bg-primary w-fit mx-auto text-white py-3 items-center justify-center rounded  px-[15px] font-medium leading-none  shadow-md outline-none outline-offset-1 hover:bg-mauve3 focus-visible:outline-2 focus-visible:outline-violet6 select-none`,
            isActive ? "bg-primary" : "bg-neutral-900"
          )}
        >
          Get Started now
        </button>
      </Trigger>
    </div>
  );
};

export default PricingCard;
