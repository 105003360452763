import { ValidationForm } from "../services/ValidationForm";
import BaseInput from "./BaseInput";
import FormLabel from "../components/FormLabel";
import ValidationErrorLabel from "./ValidationErrorLabel";
import PrimaryButton from "./PrimaryButton";
import WithWarningAalert from "./HOCs/WithWarningAalert";
import { useForm } from "react-hook-form";
import Calendar from "./Calendar";

import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
const BookingForm = () => {
  const {
    register,

    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleSubmitForm = (values) => {
    console.log(
      process.env.REACT_APP_EMAIL_PUBLIC_ID,

      process.env.REACT_APP_EMAIL_TEMPLATE_POPUP_ID,
      process.env.REACT_APP_EMAIL_SERVICES_POPUP_ID
    );
    toast.promise(
      emailjs.send(
        process.env.REACT_APP_EMAIL_SERVICES_POPUP_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_POPUP_ID,
        { ...values },
        {
          publicKey: process.env.REACT_APP_EMAIL_PUBLIC_ID,
        }
      ),
      {
        pending: "please wait ",
        success: "thank you for connection👌",
        error: {
          render: (res) => {
            console.log(res);
            return "error";
          },
        },
      }
    );

    // const promise = new Promise((resolve, reg) => {
    //   SendEmail(values).then(([status, errors]) => {
    //     if (errors) return reg(errors);
    //     return resolve(status);
    //   });
    // });
    // toast.promise(promise, {
    //   pending: "please wait ",
    //   success: "thank you for connection👌",
    //   error: "sorry you can contact us by phone number 🤯",
    // });
  };
  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className=" text-sm space-y-5"
    >
      <div className="flex gap-3  max-lg:flex-col">
        <div className="flex-1 ">
          <FormLabel> Full name</FormLabel>
          <div>
            <BaseInput
              placeholder="Enter Full Name"
              {...register("fullName", ValidationForm.FullName)}
            />

            <WithWarningAalert
              errors={errors}
              Component={ValidationErrorLabel}
              filedName="fullName"
            />
          </div>
        </div>
        <div className="flex-1 ">
          <FormLabel> Email</FormLabel>

          <div>
            <BaseInput
              type="email"
              placeholder="Email Address"
              {...register("email", ValidationForm.email)}
            />
            <WithWarningAalert
              errors={errors}
              Component={ValidationErrorLabel}
              filedName="email"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 max-lg:flex-col">
        <div className="flex-1">
          <FormLabel> company </FormLabel>
          <div>
            <BaseInput
              placeholder="Company Name"
              {...register("company", ValidationForm.company)}
            />
            <WithWarningAalert
              errors={errors}
              Component={ValidationErrorLabel}
              filedName="company"
            />
          </div>
        </div>
        <div className="flex-1">
          <FormLabel> Phone</FormLabel>
          <div>
            <BaseInput
              placeholder="Phone Number"
              {...register("phone", ValidationForm.phone)}
            />{" "}
            <WithWarningAalert
              errors={errors}
              Component={ValidationErrorLabel}
              filedName="phone"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 max-lg:flex-col">
        <div className=" flex-1 ">
          <FormLabel> Date/Time </FormLabel>
          <Calendar
            className="rounded-lg"
            placeholder="Date/Time"
            {...register("Calendar", {
              required: "please choose a calendar",
            })}
          />
        </div>

        <div className=" flex-1  gap-3">
          <FormLabel> Budget </FormLabel>
          <BaseInput
            placeholder="What is your monthly budget?"
            {...register("Budget", {
              required: "please choose a calendar",
            })}
          />
        </div>
      </div>
      <div className="flex max-lg:flex-col flex-wrap gap-4">
        <div className=" flex-1   gap-3">
          <FormLabel> How Many Callers Are You Interested in?</FormLabel>
          <BaseInput
            placeholder="How Many Callers Are You Interested in?"
            {...register("CallersNumber", {
              required: "please choose a calendar",
            })}
          />
        </div>
        <div className=" flex-1  gap-3">
          <FormLabel>
            Do you close the leads yourself or you have a team?
          </FormLabel>
          <BaseInput
            placeholder="answer you question"
            className="placeholder:text-sm"
            {...register("leaderType", {
              required: "please choose a calendar",
            })}
          />
        </div>
      </div>
      <div className="flex max-lg:flex-col flex-wrap gap-4">
        <div className=" text-xs  flex flex-col flex-1  gap-2">
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              {...register("consent1", {
                required: "please choose a calendar",
              })}
              id="consent1"
            />

            <label htmlFor="consent1" className="text-gray-600">
              <p className="text-gray-600">
                consent to receive text messages and emails from Dial Masters
                Solutions about different marketing offers at the phone number
                and email I provided.
              </p>
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              {...register("consent2", {
                required: "please choose a calendar",
              })}
              id="consent2"
            />
            <label htmlFor="consent2" className="text-gray-600">
              consent to receive text messages and emails such as lead
              generation and different sales opportunities from Dial Masters
              Solutions at the phone number and email I provided including
              messages and calls via an auto dialer
            </label>
          </div>
        </div>
      </div>
      <PrimaryButton type="submit">Book</PrimaryButton>
    </form>
  );
};

export default BookingForm;
