import React from "react";
import Logo from "../components/Logo";
const FooterLinks = [
  {
    header: "Quick Links",
    links: [
      { link: "About", url: "#home-section" },
      { link: "Plans ", url: "#pricing_section" },
      { link: "insights", url: "#Insights" },
      { link: "contact", url: "#contact-form" },
    ],
  },
];
function LinksFooter() {
  return (
    <footer>
      <div className="container mx-auto">
        <div className="flex max-md:flex-col md:divide-x-2 md:divide-black ">
          <div className="flex-1">
            <Logo />
            <p>
              We power your success with expert cold calling. Our team calls
              untapped leads identify warm prospects , driving your sales by
            </p>
          </div>
          <div className="flex  max-md:flex-col flex-1 md:divide-x-2 md:divide-black gap-10">
            {FooterLinks.map((link, index) => {
              return (
                <div key={index} className="px-3 flex-1  max-md:flex-col">
                  <h1 className="text-2xl font-bold">{link.header}</h1>
                  <ul>
                    {link.links.map((link, index) => {
                      return (
                        <li className="flex flex-col">
                          <a href={link.url}>
                            <label className="text-xl font-semibold">
                              {link.label}
                            </label>
                            <span className="underline" href={link.url}>
                              {link.link}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
            <div className="px-3 flex-1  max-md:flex-col">
              <h1 className="text-2xl font-bold">Content</h1>
              <ul>
                <li className="flex flex-col">
                  <label className="text-xl font-semibold">Email</label>
                  <span className="underline">
                    info@dialmasterssolutions.com
                  </span>
                </li>
                <li className="flex flex-col">
                  <label className="text-xl font-semibold">sales Team</label>
                  <span className="underline">Phone:(904) 206-717</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default LinksFooter;
