import React from "react";

const AdvantageCard = ({
  className = "",
  imgUrl,
  desc,
  withTitle,
  paragraphClassName = "",
  ...props
}) => {
  return (
    <div
      {...props}
      className={`items-center pt-16  gap-x-12 sm:px-4 overflow-hidden md:px-0 lg:flex ${className}`}
    >
      <div className="flex-1 sm:hidden lg:block" data-aos="fade-left">
        <img src={imgUrl} className="md:max-w-lg sm:rounded-lg" alt="" />
      </div>
      <div
        data-aos-delay="500"
        data-aos="fade-in"
        className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl"
      >
        {withTitle && (
          <p className=" text-3xl text-primary font-semibold sm:text-4xl">
            How it works
          </p>
        )}

        <p className={`mt-3 text-gray-600 ${paragraphClassName}`}>{desc}</p>
      </div>
    </div>
  );
};

export default AdvantageCard;
