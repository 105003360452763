const HeroSection = () => {
  return (
    <section
      className="py-28"
      data-aos="fade-in"
      data-aos-delay="950"
      data-aos-duration="1500"
      id="home-section"
    >
      <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
        <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl">
          {/* <h1 className="text-sm text-indigo-600 font-medium">
            Over 200 successful deals
          </h1> */}
          <h2 className="text-3xl text-gray-800 capitalize font-extrabold md:text-4xl">
            Get more qulified leads generated by well-trained VAs,
            Close more deals.
          </h2>
          <h2 className="text-2xl text-gray-800 capitalize font-medium">
            We help real estate investors, realtors, and brokerages close more
            deals
          </h2>
          <p>
            <span className="text-4xl font-bold text-primary pe-2">DMS</span>
            provides virtual assistants who have at least 3 years experience in
            the real estate industry, and can get you more qualifed leads from
            calling your data lists. <br /> Proven model for scaling brokerages
            & Wholesaling Operations.
          </p>
          <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
            <a
              href="#contact-form"
              className="block py-2 px-4 text-center text-white font-medium bg-primary duration-150 hover:bg-indigo-800 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none"
            >
              Let's get started
            </a>
          </div>
        </div>
        <div className="flex-none mt-14 md:mt-0 md:max-w-xl">
          <img
            src="/background/blog1.webp"
            className=" md:rounded-tl-[108px]"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
