import React from "react";
import { Link } from "react-router-dom";

function Logo() {
  return (
    <Link to={"/"} className="flex items-center">
      <img
        src="/logo.png"
        alt="Dial Masters Solutions (DMS)"
        className="h-[85px] object-contain"
      />{" "}
      <span className=" -translate-x-5 text-primary">
        Dial Masters Solutions
      </span>
    </Link>
  );
}

export default Logo;
