import React, { memo } from "react";
import ReactPlayer from "react-player";

function CustomerVideoSection() {
  return (
    <div className=" container mx-auto relative h-full">
      <div className="grid gap-5 md:grid-cols-2  ">
        <div className="text-center">
          <div className="w-fit  rounded-md    h-[400px] overflow-hidden   relative mx-auto">
            <ReactPlayer
              url={"/clientV.mp4"}
              controls={true}
              width="100%"
              height="100%"
              style={{
                height: "100%",
              }}
            />
            <div className="absolute bottom-0 inset-0 from-primary via-transparent to-secondary  opacity-70   bg-gradient-to-tl pointer-events-none   z-10" />
          </div>{" "}
          <h1 className="text-xl font-bold text-gray-600">C. Adriana</h1>
          <p className="text-lg font-semibold text-gray-500">
            Real Estate Investor/ Realtor
          </p>
        </div>
        <div className="text-center flex flex-col items-center justify-center">
          <div className="w-fit mx-auto rounded-md overflow-hidden  h-[400px]   relative  mr-auto">
            <ReactPlayer
              url={"/clientD.mp4"}
              controls={true}
              width="100%"
              height="100%"
            />
            <div className="absolute inset-0 from-primary via-transparent to-secondary  opacity-70   bg-gradient-to-tl pointer-events-none   z-0" />
          </div>{" "}
          <h1 className="text-xl font-bold text-gray-600">F.Melissa</h1>
          <p className="text-lg font-semibold text-gray-500">
            Real Estate Investor / Wholesaler
          </p>
        </div>
      </div>
    </div>
  );
}

export default memo(CustomerVideoSection);
