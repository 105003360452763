import React from "react";
import cn from "./utils/cn";

const BaseInput = React.forwardRef(
  ({ type = "text", className, ...props }, ref) => {
    return (
      <input
        ref={ref}
        type={type}
        className={cn(
          "w-full max-md:text-sm md:text-sm max-sm:text-xs mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-primary shadow-sm rounded-lg",
          className
        )}
        {...props}
      />
    );
  }
);

export default BaseInput;
