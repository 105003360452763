import React from "react";
import * as ReviewCard from "../components/ReviewCard";

const testimonials = [
  {
    name: "D.Danielle",
    message:
      "I am very happy! Ben is very communicative, kind, professional, punctual and many other great qualities. He also shows interest to grow my start-up wholesaling business which is significant to me. Results. Thx for providing great services. I am looking forward to working with you to grow my business. I have hope!",
    avatar: null,
    type: "female",
    props: {
      className: "col-span-6 sm:col-span-4 lg:col-span-3",
      aos: {
        "data-aos": "fade-right",
      },
    },
  },

  {
    name: "T. David",
    message:
      "Thanks Ibrahim! Your VAs are fierce grinders and absolute killers. DMS is setting a new standard for us.",
    avatar: "/clientMessages/David.jpg",
    props: {
      className: "col-span-6 sm:col-span-2 lg:col-span-3",
      aos: {
        "data-aos": "fade-left",
      },
    },
  },
  {
    name: "H.Jack",
    message:
      "Good news. Got 2 of your cold calls leads locked up and assigned, just waiting for them to close. For only being 1 month or so in, that is impressive! Looking forward to an incredible year with you guys! Good work",
    avatar: null,
    props: {
      className: "col-span-6 md:col-span-2 lg:col-span-2",
      aos: {
        "data-aos": "zoom-in",
      },
    },
  },
  {
    name: "H.Mike",
    message:
      "I wanted to share a fantastic resource that could greatly benefit your business that I have worked with before. Ibrahim Gamal has his own virtual assistant company, and they are exceptional at what they do. Their team excels in communication, especially when it comes to handling language barriers with prospective clients. They are incredibly proactive, always staying on top of tasks whether it's scaling up volume, following up, or diving deep to understand the nuances of your business operations. Working with Ibrahim and his team has been a great experience, and I believe they can provide the same high level of service for you. If you're looking for reliable, efficient, and highly communicative virtual assistants, I highly recommend working with Ibrahim.",
    avatar: "/clientMessages/Mike.jpg",
    props: {
      className: "col-span-6 sm:col-span-6 md:col-span-4 lg:col-span-4",
      aos: {
        "data-aos": "fade-left",
      },
    },
  },
];

const ClientsMessageSection = () => {
  return (
    <section className="py-20  overflow-hidden   bg-secondary">
      <div className="max-w-7xl grid mx-auto px-6 md:px-12 xl:px-6">
        <div className="mb-10 space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold text-white dark:text-white md:text-4xl">
            Clients' Testimonials
          </h2>
        </div>
      </div>
      <div className="container  mx-auto">
        <div className="grid  grid-cols-6 gap-8 ">
          {testimonials.map(({ avatar, message, name, type, props }) => {
            return (
              <ReviewCard.Root
                className={props?.className}
                {...props.aos}
                data-aos-offset="200"
              >
                <div className="flex h-fit items-center gap-4">
                  <ReviewCard.Avatar
                    url={
                      type === "female"
                        ? "/femaleAvatar.jpg"
                        : "/defualt_Investor_avatar .jpg"
                    }
                    alt="Reviewer 1"
                  />
                  <div className="flex flex-col">
                    <ReviewCard.Header className="mt-auto">
                      {name}
                    </ReviewCard.Header>
                    <ReviewCard.Description
                      className={
                        "text-start text-sm font-semibold text-gray-500 mt-0"
                      }
                    >
                      REAL ESTATE INVESTOR
                    </ReviewCard.Description>
                  </div>
                </div>
                <ReviewCard.Description>{message}</ReviewCard.Description>
              </ReviewCard.Root>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ClientsMessageSection;
